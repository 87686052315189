<template>
  <div class="form-wrapper">
    <div
      v-show="isMaximized"
      :class="['modal-backdrop', { hidden: !showForm }]"
    ></div>

    <div
      ref="emailForm"
      :class="[
        'email-form bg-white',
        { minimized: isMinimized, hidden: !showForm }
      ]"
      :style="formStyles"
    >
      <!-- Header -->
      <b-row class="border-bottom m-0 header-drag" @mousedown="handleMouseDown">
        <b-col
          cols="12"
          class="d-flex justify-content-between align-items-center p-2 header-bg"
        >
          <h5 class="mb-0 header-title">Compose Email</h5>
          <div class="d-flex align-items-center">
            <b-button
              variant="link"
              class="p-0 mr-2 custom-btn"
              @click="toggleMaximize"
            >
              <i :class="['fa', isMaximized ? 'fa-compress' : 'fa-expand']" />
            </b-button>
            <b-button
              variant="link"
              class="p-0 custom-btn"
              @click="onMinimizeClick"
            >
              <i
                :class="[
                  'fa',
                  isMinimized ? 'fa-chevron-up' : 'fa-chevron-down'
                ]"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- Form Content -->
      <div v-show="!isMinimized" class="form-content" :style="contentStyles">
        <inline-input
          :id="controls.email.id"
          v-model="formData.email"
          :mode="this.$constants.FORM_MODE.EDIT"
          :label="controls.email.label"
          :required="true"
        />
        <inline-input
          :id="controls.subject.id"
          v-model="formData.subject"
          :mode="this.$constants.FORM_MODE.EDIT"
          :label="controls.subject.label"
          :required="true"
        />

        <inline-select
          :id="controls.templates.id"
          v-model="formData.template"
          :mode="this.$constants.FORM_MODE.EDIT"
          :options="controls.templates.options"
          :label="controls.templates.label"
          @changed="onTemplateChange"
        />
        <rich-text-editor v-model="formData.body" class="mb-3" />
        <!--
        <inline-input
          :id="controls.body.id"
          v-model="formData.body"
          :mode="this.$constants.FORM_MODE.EDIT"
          :label="controls.body.label"
          :isTextArea="true"
          :rows="20"
          :required="true"
        />
      --></div>

      <!-- Footer -->
      <div
        v-show="!isMinimized"
        class="form-footer position-absolute bottom-1 w-100 p-2 bg-white footer-border"
      >
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1 width10"
            @click="onSendClick"
          >
            <i class="fa fa-paper-plane mr-1"></i> Send
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1 width10"
            @click="onCancelClick"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelect'
import RichTextEditor from '@/components/RichTextEditor'

export default {
  name: 'EmailForm',
  components: {
    InlineInput,
    InlineSelect,
    RichTextEditor
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isMaximized: false,
      isMinimized: false,
      showForm: false,
      position: { x: null, y: null },
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      selectedTemplate: null,
      messageText: '',

      controls: {
        sendCopy: {
          id: 'sendCopy',
          label: 'Send Copy'
        },
        email: {
          id: 'email',
          label: 'Email'
        },
        subject: {
          id: 'subject',
          label: 'Subject'
        },
        body: {
          id: 'body',
          label: 'Body'
        },
        templates: {
          id: 'templates',
          label: 'Templates',
          options: [
            { id: 'select', label: 'Select template' },

            { id: 'template1', label: 'Portal account' },
            {
              id: 'template2',
              label: 'Portal account and koval-distillery.com'
            },
            { id: 'template3', label: 'Portal account and Google Worksuite' }
          ]
        }
      },
      formData: {
        email: '',
        subject: '',
        body: '',
        template: {},
        templates: [],
        sendCopy: false,
        user: {
          first_name: '',
          user_name: '',
          email: ''
        }
      }
    }
  },

  computed: {
    formStyles () {
      if (this.isMaximized) {
        return {
          width: '80%',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          resize: 'none'
        }
      }

      const styles = {
        width: '580px',
        right: '20px',
        bottom: '20px'
      }

      if (this.position.x !== null && this.position.y !== null) {
        styles.right = 'auto'
        styles.bottom = 'auto'
        styles.left = `${this.position.x}px`
        styles.top = `${this.position.y}px`
      }

      if (this.isMinimized) {
        styles.height = '40px'
        styles.resize = 'none'
      } else {
        styles.height = '80vh'
        styles.minHeight = '80vh'
        styles.resize = 'both'
      }

      return styles
    },

    contentStyles () {
      const baseStyles = {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '1rem'
      }

      // Adjust content height based on maximized state
      if (this.isMaximized) {
        return {
          ...baseStyles,
          height: 'calc(100% - 100px)' // Reduced padding for maximized state
        }
      }

      return {
        ...baseStyles,
        height: 'calc(100% - 100px)'
      }
    },

    computedTextareaStyles () {
      const baseStyles = {
        height: '100%'
      }

      if (this.isMaximized) {
        return {
          ...baseStyles,
          minHeight: '300px' // Larger minimum height when maximized
        }
      }

      return {
        ...baseStyles,
        minHeight: '150px'
      }
    }
  },

  async mounted () {},
  methods: {
    onTemplateChange (id, val) {
      if (val.id === 'select') {
        this.formData.body = ''
        return
      }
      const templateId = val.id

      this.$api
        .get(`/users/email-templates/${templateId}?random=${Math.random()}`, {
          responseType: 'blob'
        })
        .then(response => {
          //     console.log('onTemplateChange:', response)

          this.formData.body = this.replaceTemplateVariables(response)
        })
        .catch(error => {
          this.formData.body = ''

          this.$form.makeToastError('Error fetching template')
          console.error('Error fetching template:', error)
        })
    },
    replaceTemplateVariables (template) {
      console.log('replaceTemplateVariables:', this.formData)
      const user = this.formData.user
      const variables = {
        '{{first_name}}': user.first_name,
        '{{last_name}}': user.last_name,
        '{{first_name.last_name}}': (
          user.first_name +
          '.' +
          user.last_name
        ).toLowerCase(),
        '{{user_name}}': user.user_name,
        '{{password}}': 'PASSWORD'
      }

      return Object.keys(variables).reduce((acc, key) => {
        return acc.replace(new RegExp(key, 'g'), variables[key])
      }, template)
    },
    async onSendClick () {
      const confirmed = await this.$form.showConfirmation(
        'Are you sure you want to send this email?'
      )

      let payload = {
        from_email: 'no-reply@koval-distillery.com',
        from_name: 'Koval CRM',
        email: this.formData.email,
        subject: this.formData.subject,
        body: this.formData.body
      }
      if (confirmed) {
        try {
          await this.$api.post('/send-email', payload)
          this.$form.makeToastInfo('Email sent successfully')

          this.showForm = false
        } catch (error) {
          this.$form.makeToastError('Error sending email')
          console.error('Error sending email:', error)
        }
      }
    },
    onCancelClick () {
      this.showForm = false
    },
    onMinimizeClick () {
      this.isMinimized = !this.isMinimized
      if (this.isMinimized) {
        this.isMaximized = false
      }
    },

    toggleMaximize () {
      this.isMaximized = !this.isMaximized
      this.isMinimized = false
      if (this.isMaximized) {
        this.position = { x: null, y: null }
      }
    },
    show (payload) {
      console.log('EmailForm.show:', payload)
      this.formData.user = payload.user
      this.formData.email = payload.user.email2
      this.formData.subject = payload.subject
      this.formData.body = payload.body

      this.showForm = true
    },
    handleMouseDown (e) {
      if (this.isMaximized) return

      this.isDragging = true
      const rect = this.$refs.emailForm.getBoundingClientRect()
      this.dragOffset = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      }

      document.addEventListener('mousemove', this.handleMouseMove)
      document.addEventListener('mouseup', this.handleMouseUp)
    },

    handleMouseMove (e) {
      if (!this.isDragging) return

      e.preventDefault()
      const x = e.clientX - this.dragOffset.x
      const y = e.clientY - this.dragOffset.y

      const maxX = window.innerWidth - this.$refs.emailForm.offsetWidth
      const maxY = window.innerHeight - this.$refs.emailForm.offsetHeight

      this.position = {
        x: Math.min(Math.max(0, x), maxX),
        y: Math.min(Math.max(0, y), maxY)
      }
    },

    handleMouseUp () {
      this.isDragging = false
      document.removeEventListener('mousemove', this.handleMouseMove)
      document.removeEventListener('mouseup', this.handleMouseUp)
    }
  },
  watch: {
    isVisible (newVal) {
      this.showForm = newVal
    }
  }
}
</script>

<style scoped>
.width10 {
  width: 10em;
}
.form-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1040;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.email-form {
  position: fixed;
  z-index: 1050;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  border: 0.3px solid rgba(47, 53, 58, 0.2);
  transition: all 0.3s ease;
  max-height: calc(100vh - 40px);
  overflow: hidden;
}

.hidden {
  display: none;
}

.header-drag {
  cursor: move;
  user-select: none;
}

.header-bg {
  background-color: #2f353a;
}

.header-title {
  color: #f8f9fa;
  font-weight: 400;
}

.footer-border {
  border-top: 0.3px solid rgba(47, 53, 58, 0.2) !important;
}

.custom-btn {
  color: #f8f9fa;
}

.custom-btn-fixed {
  width: 120px;
  color: #f8f9fa;
  border: 0.3px solid rgba(47, 53, 58, 0.3);
}

.custom-btn-fixed:hover {
  color: white;
  background-color: #2f353a;
  border-color: #2f353a;
}

.email-form :deep(.form-control) {
  min-height: unset;
  border: 0.3px solid rgba(47, 53, 58, 0.2);
}

.email-form :deep(.form-control:focus) {
  border-color: rgba(47, 53, 58, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.1);
}

.email-form :deep(.custom-select) {
  border: 0.3px solid rgba(47, 53, 58, 0.2);
  background-color: white;
  color: #2f353a;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

.email-form :deep(.custom-select:focus) {
  border-color: rgba(47, 53, 58, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.1);
}

.email-form :deep(.form-control:hover),
.email-form :deep(.custom-select:hover) {
  border-color: rgba(47, 53, 58, 0.4);
}

.email-form :deep(.border),
.email-form :deep(.border-top),
.email-form :deep(.border-bottom),
.email-form :deep(.border-left),
.email-form :deep(.border-right) {
  border-width: 0.3px !important;
  border-color: rgba(47, 53, 58, 0.2) !important;
}

.minimized {
  resize: none !important;
}
</style>
