var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', [_vm._v("User details")]), _c('hr'), _vm.isProfile ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v("Please update Oleg Raskin and Sebastian Saller when any of your personal information changes. ")]) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.user_name.id,
      "value": _vm.user.user_name,
      "readonly": !_vm.$permitted(_vm.controls.user_name.id).write,
      "mode": _vm.mode,
      "label": _vm.controls.user_name.label,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('form', {
    attrs: {
      "autocomplete": "off"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.user_password.id,
      "value": _vm.user.user_password,
      "type": 'password',
      "label": _vm.controls.user_password.label,
      "mode": _vm.mode,
      "required": _vm.controls.user_password.required
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.email1.id,
      "value": _vm.user.email1,
      "label": _vm.controls.email1.label,
      "readonly": !_vm.$permitted(_vm.controls.email1.id).write,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.email2.id,
      "value": _vm.user.email2,
      "label": _vm.controls.email2.label,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_birth.id,
      "value-single": _vm.user.date_birth,
      "label": _vm.controls.date_birth.label,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.first_name.id,
      "value": _vm.user.first_name,
      "label": _vm.controls.first_name.label,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.last_name.id,
      "value": _vm.user.last_name,
      "label": _vm.controls.last_name.label,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.phone_mobile.id,
      "value": _vm.user.phone_mobile,
      "label": _vm.controls.phone_mobile.label,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.is_admin.id,
      "value": _vm.user.is_admin,
      "label": _vm.controls.is_admin.label,
      "readonly": !_vm.$permitted(_vm.controls.is_admin.id).write,
      "options": _vm.controls.is_admin.options,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.role.id,
      "value": _vm.user.role,
      "label": _vm.controls.role.label,
      "readonly": !_vm.$permitted(_vm.controls.role.id).write,
      "options": _vm.controls.role.options,
      "required": true,
      "allow-empty": false,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.department.id,
      "value": _vm.user.department,
      "label": _vm.controls.department.label,
      "readonly": !_vm.$permitted(_vm.controls.department.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "col-form-label",
    attrs: {
      "for": "groups"
    }
  }, [_vm._v("Groups")]), _c('div', {
    staticClass: "form-group"
  }, [_vm.mode === _vm.$constants.FORM_MODE.VIEW && _vm.controls.groups.readonly ? _c('textarea', {
    staticClass: "form-control",
    attrs: {
      "rows": "2",
      "readonly": _vm.mode === _vm.$constants.FORM_MODE.VIEW ? _vm.controls.groups.readonly : false,
      "type": "text",
      "id": "groups-input"
    },
    domProps: {
      "value": _vm.controls.groups.as_string
    },
    on: {
      "change": function change($event) {
        return _vm.changeField($event);
      },
      "dblclick": function dblclick($event) {
        return _vm.dblclickField($event);
      }
    }
  }) : _vm._e(), !_vm.controls.groups.readonly ? _c('multiselect', {
    attrs: {
      "id": "groups-select",
      "close-on-select": false,
      "track-by": "groupid",
      "label": "groupname",
      "options": _vm.controls.groups.options,
      "deselect-label": "",
      "select-label": "",
      "multiple": true
    },
    on: {
      "input": _vm.changeSelect,
      "close": _vm.closeSelect
    },
    model: {
      value: _vm.controls.groups.value,
      callback: function callback($$v) {
        _vm.$set(_vm.controls.groups, "value", $$v);
      },
      expression: "controls.groups.value"
    }
  }) : _vm._e()], 1)])])], 1), _c('h5', [_vm._v("User address")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.address_street.id,
      "value": _vm.user.address_street,
      "label": _vm.controls.address_street.label,
      "readonly": !_vm.$permitted(_vm.controls.address_street.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.address_country.id,
      "value": _vm.user.address_country,
      "label": _vm.controls.address_country.label,
      "readonly": !_vm.$permitted(_vm.controls.address_country.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.address_city.id,
      "value": _vm.user.address_city,
      "label": _vm.controls.address_city.label,
      "readonly": !_vm.$permitted(_vm.controls.address_city.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.address_state.id,
      "value": _vm.user.address_state,
      "label": _vm.controls.address_state.label,
      "readonly": !_vm.$permitted(_vm.controls.address_state.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.address_postalcode.id,
      "value": _vm.user.address_postalcode,
      "label": _vm.controls.address_postalcode.label,
      "readonly": !_vm.$permitted(_vm.controls.address_postalcode.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("More information")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.phone_work.id,
      "value": _vm.user.phone_work,
      "label": _vm.controls.phone_work.label,
      "readonly": !_vm.$permitted(_vm.controls.phone_work.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.phone_home.id,
      "value": _vm.user.phone_home,
      "label": _vm.controls.phone_home.label,
      "readonly": !_vm.$permitted(_vm.controls.phone_home.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.title.id,
      "value": _vm.user.title,
      "label": _vm.controls.title.label,
      "readonly": !_vm.$permitted(_vm.controls.title.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "3",
      "xl": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.reports_to.id,
      "value": _vm.user.reports_to,
      "label": _vm.controls.reports_to.label,
      "readonly": !_vm.$permitted(_vm.controls.reports_to.id).write,
      "options": _vm.controls.reports_to.options,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.description.id,
      "value": _vm.user.description,
      "label": _vm.controls.description.label,
      "readonly": !_vm.$permitted(_vm.controls.description.id).write,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      },
      "custom-buttons": _vm.customButtons
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Users'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'User submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'User submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      },
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  }), _c('WelcomeEmail', {
    ref: "refEmailForm",
    attrs: {
      "isVisible": _vm.emailForm.visible
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }