var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rich-text-editor border rounded"
  }, [_c('div', {
    staticClass: "toolbar bg-gray-100 p-2 border-b d-flex gap-2 flex-wrap"
  }, [_c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('bold');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-bold"
  })]), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('italic');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-italic"
  })]), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('underline');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-underline"
  })]), _c('div', {
    staticClass: "vr mx-2"
  }), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('insertUnorderedList');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-list-ul"
  })]), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('insertOrderedList');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-list-ol"
  })]), _c('div', {
    staticClass: "vr mx-2"
  }), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('justifyLeft');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-align-left"
  })]), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('justifyCenter');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-align-center"
  })]), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.execCommand('justifyRight');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-align-right"
  })]), _c('div', {
    staticClass: "vr mx-2"
  }), _c('b-button', {
    attrs: {
      "variant": "light",
      "size": "sm"
    },
    on: {
      "click": _vm.insertLink
    }
  }, [_c('i', {
    staticClass: "fa fa-link"
  })]), _c('b-button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "variant": "light",
      "size": "sm",
      "title": "Copy HTML"
    },
    on: {
      "click": _vm.copyHtml
    }
  }, [_c('i', {
    staticClass: "fa fa-code"
  })])], 1), _c('div', {
    ref: "editor",
    staticClass: "editor p-2",
    attrs: {
      "contenteditable": "true"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    },
    on: {
      "input": _vm.updateContent,
      "paste": _vm.handlePaste
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }