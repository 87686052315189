<template>
  <div class="rich-text-editor border rounded">
    <div class="toolbar bg-gray-100 p-2 border-b d-flex gap-2 flex-wrap">
      <b-button variant="light" size="sm" @click="execCommand('bold')">
        <i class="fa fa-bold"></i>
      </b-button>
      <b-button variant="light" size="sm" @click="execCommand('italic')">
        <i class="fa fa-italic"></i>
      </b-button>
      <b-button variant="light" size="sm" @click="execCommand('underline')">
        <i class="fa fa-underline"></i>
      </b-button>
      <div class="vr mx-2"></div>
      <b-button
        variant="light"
        size="sm"
        @click="execCommand('insertUnorderedList')"
      >
        <i class="fa fa-list-ul"></i>
      </b-button>
      <b-button
        variant="light"
        size="sm"
        @click="execCommand('insertOrderedList')"
      >
        <i class="fa fa-list-ol"></i>
      </b-button>
      <div class="vr mx-2"></div>
      <b-button variant="light" size="sm" @click="execCommand('justifyLeft')">
        <i class="fa fa-align-left"></i>
      </b-button>
      <b-button variant="light" size="sm" @click="execCommand('justifyCenter')">
        <i class="fa fa-align-center"></i>
      </b-button>
      <b-button variant="light" size="sm" @click="execCommand('justifyRight')">
        <i class="fa fa-align-right"></i>
      </b-button>
      <div class="vr mx-2"></div>
      <b-button variant="light" size="sm" @click="insertLink">
        <i class="fa fa-link"></i>
      </b-button>
      <b-button
        variant="light"
        size="sm"
        @click="copyHtml"
        v-b-tooltip
        title="Copy HTML"
      >
        <i class="fa fa-code"></i>
      </b-button>
    </div>

    <div
      ref="editor"
      class="editor p-2"
      contenteditable="true"
      @input="updateContent"
      @paste="handlePaste"
      v-html="content"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'RichTextEditor',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  methods: {
    execCommand (command, value = null) {
      document.execCommand(command, false, value)
      this.updateContent()
    },
    updateContent () {
      const content = this.$refs.editor.innerHTML
      this.$emit('input', content)
    },
    handlePaste (e) {
      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      document.execCommand('insertText', false, text)
    },
    insertLink () {
      const url = prompt('Enter URL:')
      if (url) {
        document.execCommand('createLink', false, url)
      }
    },
    copyHtml () {
      const html = this.$refs.editor.innerHTML
      navigator.clipboard.writeText(html)
      this.$form.makeToastInfo('HTML copied to clipboard')
    }
  },
  watch: {
    value (newVal) {
      if (newVal !== this.$refs.editor.innerHTML) {
        this.content = newVal
      }
    }
  }
}
</script>

<style scoped>
.rich-text-editor {
  border: 0.3px solid rgba(47, 53, 58, 0.2);
}

.editor {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
}

.vr {
  width: 1px;
  background-color: #dee2e6;
  align-self: stretch;
}
</style>
