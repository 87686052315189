var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrapper"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMaximized,
      expression: "isMaximized"
    }],
    class: ['modal-backdrop', {
      hidden: !_vm.showForm
    }]
  }), _c('div', {
    ref: "emailForm",
    class: ['email-form bg-white', {
      minimized: _vm.isMinimized,
      hidden: !_vm.showForm
    }],
    style: _vm.formStyles
  }, [_c('b-row', {
    staticClass: "border-bottom m-0 header-drag",
    on: {
      "mousedown": _vm.handleMouseDown
    }
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-between align-items-center p-2 header-bg",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0 header-title"
  }, [_vm._v("Compose Email")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "p-0 mr-2 custom-btn",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.toggleMaximize
    }
  }, [_c('i', {
    class: ['fa', _vm.isMaximized ? 'fa-compress' : 'fa-expand']
  })]), _c('b-button', {
    staticClass: "p-0 custom-btn",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.onMinimizeClick
    }
  }, [_c('i', {
    class: ['fa', _vm.isMinimized ? 'fa-chevron-up' : 'fa-chevron-down']
  })])], 1)])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMinimized,
      expression: "!isMinimized"
    }],
    staticClass: "form-content",
    style: _vm.contentStyles
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.email.id,
      "mode": this.$constants.FORM_MODE.EDIT,
      "label": _vm.controls.email.label,
      "required": true
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  }), _c('inline-input', {
    attrs: {
      "id": _vm.controls.subject.id,
      "mode": this.$constants.FORM_MODE.EDIT,
      "label": _vm.controls.subject.label,
      "required": true
    },
    model: {
      value: _vm.formData.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "subject", $$v);
      },
      expression: "formData.subject"
    }
  }), _c('inline-select', {
    attrs: {
      "id": _vm.controls.templates.id,
      "mode": this.$constants.FORM_MODE.EDIT,
      "options": _vm.controls.templates.options,
      "label": _vm.controls.templates.label
    },
    on: {
      "changed": _vm.onTemplateChange
    },
    model: {
      value: _vm.formData.template,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "template", $$v);
      },
      expression: "formData.template"
    }
  }), _c('rich-text-editor', {
    staticClass: "mb-3",
    model: {
      value: _vm.formData.body,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "body", $$v);
      },
      expression: "formData.body"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMinimized,
      expression: "!isMinimized"
    }],
    staticClass: "form-footer position-absolute bottom-1 w-100 p-2 bg-white footer-border"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1 width10",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.onSendClick
    }
  }, [_c('i', {
    staticClass: "fa fa-paper-plane mr-1"
  }), _vm._v(" Send ")]), _c('b-button', {
    staticClass: "m-1 width10",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.onCancelClick
    }
  }, [_vm._v(" Cancel ")])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }